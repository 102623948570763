import Vue                 from 'vue'
import { StatusIndicator } from 'vue-status-indicator'
import ErrorBox            from './error-box'
import WekaSpinner         from './weka-spinner'
import Intercom            from './intercom'
import Modal               from './modal'
import PaginatedTable      from './paginated-table'
import Recaptcha           from './recaptcha'
import User                from './user'
import Organization        from './organization'
import ClusterRow          from './cluster-row'
import Cluster             from './cluster'
import PaymentMethod       from './payment-method'
import Entitlement         from './entitlement'
import LicenseDetails      from './license-details'
import ClusterGuidInput    from './cluster-guid-input'
import UsageReport         from './usage-report'

Vue.component(StatusIndicator.name  , StatusIndicator)
Vue.component(ErrorBox.name         , ErrorBox)
Vue.component(WekaSpinner.name      , WekaSpinner)
Vue.component(Intercom.name         , Intercom)
Vue.component(Modal.name            , Modal)
Vue.component(PaginatedTable.name   , PaginatedTable)
Vue.component(Recaptcha.name        , Recaptcha)
Vue.component(User.name             , User)
Vue.component(Organization.name     , Organization)
Vue.component(ClusterRow.name       , ClusterRow)
Vue.component(Cluster.name          , Cluster)
Vue.component(PaymentMethod.name    , PaymentMethod)
Vue.component(Entitlement.name      , Entitlement)
Vue.component(LicenseDetails.name   , LicenseDetails)
Vue.component(ClusterGuidInput.name , ClusterGuidInput)
Vue.component(UsageReport.name      , UsageReport)
