<template>
  <div>
    <div style="line-height: 2.75rem">
      <table class="right-padded-cells">
        <tr v-if="entitlement.created_at">
          <td>
            <strong>
              <font-awesome-icon
                :icon="['far', 'fa-calendar-days']"
                class="fa-fw"
              ></font-awesome-icon>
              Start Date
            </strong>
          </td>
          <td>
            {{ entitlement.created_at.slice(0, 10) }}
          </td>
          <td
            v-if="showEntitlementExpired && entExpired()"
            class="label label-danger"
          >
            Entitlement Expired
          </td>
        </tr>
        <tr v-if="entitlement.seconds">
          <td>
            <strong>
              <font-awesome-icon
                :icon="['far', 'fa-clock']"
                class="fa-fw"
              ></font-awesome-icon>
              Duration
            </strong>
          </td>
          <td>{{ (entitlement.seconds / 86400).toFixed() }} days</td>
        </tr>
        <tr v-if="entitlement.enforcement_type">
          <td>
            <strong>
              <font-awesome-icon
                :icon="['fas', 'fa-user-secret']"
                class="fa-fw"
              ></font-awesome-icon>
              Enforcement Type
            </strong>
          </td>
          <td>
            {{ entitlement.enforcement_type }}
          </td>
        </tr>
        <tr v-if="entitlement.capacity_based_billing !== undefined">
          <td>
            <strong>
              <font-awesome-icon
                :icon="['fas', 'fa-database']"
                class="fa-fw"
              ></font-awesome-icon>
              Capacity Based
            </strong>
          </td>
          <td>
            {{ entitlement.capacity_based_billing ? "True" : "False" }}
          </td>
        </tr>
        <tr v-if="entitlement.location">
          <td>
            <strong>
              <font-awesome-icon
                :icon="['fas', 'fa-location-dot']"
                class="fa-fw"
              ></font-awesome-icon>
              Location
            </strong>
          </td>
          <td>
            {{ entitlement.location }}
          </td>
        </tr>
        <tr v-if="entitlement.allotted.drive_capacity_gb > 0">
          <td>
            <strong>
              <font-awesome-icon
                :icon="['far', 'fa-hard-drive']"
                class="fa-fw"
              ></font-awesome-icon>
              Raw Flash capacity
            </strong>
          </td>
          <td>
            {{
              (entitlement.allotted.drive_capacity_gb * 1000000000)
                | prettyBytes
            }}
          </td>
          <td>
            <span style="color: #999"
              >({{
                (entitlement.remaining.drive_capacity_gb * 1000000000)
                  | prettyBytes
              }}
              remaining)</span
            >
          </td>
        </tr>
        <tr v-if="entitlement.allotted.usable_capacity_gb > 0">
          <td>
            <strong>
              <font-awesome-icon
                :icon="['fas', 'fa-bolt-lightning']"
                class="fa-fw"
              ></font-awesome-icon>
              Usable Flash capacity
            </strong>
          </td>
          <td>
            {{
              (entitlement.allotted.usable_capacity_gb * 1000000000)
                | prettyBytes
            }}
          </td>
          <td>
            <span style="color: #999"
              >({{
                (entitlement.remaining.usable_capacity_gb * 1000000000)
                  | prettyBytes
              }}
              remaining)</span
            >
          </td>
        </tr>
        <tr v-if="entitlement.allotted.obs_capacity_gb">
          <td>
            <strong>
              <font-awesome-icon
                :icon="['fas', 'fa-server']"
                class="fa-fw"
              ></font-awesome-icon>
              OBS Capacity for Data Tiering
            </strong>
          </td>
          <td>
            {{
              (entitlement.allotted.obs_capacity_gb * 1000000000) | prettyBytes
            }}
          </td>
          <td>
            <span style="color: #999"
              >({{
                (entitlement.remaining.obs_capacity_gb * 1000000000)
                  | prettyBytes
              }}
              remaining)</span
            >
          </td>
        </tr>
        <tr v-if="entitlement.allotted.obs_capacity_for_data_protection_gb">
          <td>
            <strong>
              <font-awesome-icon
                :icon="['fas', 'fa-cube']"
                class="fa-fw"
              ></font-awesome-icon>
              OBS Capacity for Data Protection
            </strong>
          </td>
          <td>
            {{
              (entitlement.allotted.obs_capacity_for_data_protection_gb *
                1000000000)
                | prettyBytes
            }}
          </td>
          <td>
            <span style="color: #999"
              >({{
                (entitlement.remaining.obs_capacity_for_data_protection_gb *
                  1000000000)
                  | prettyBytes
              }}
              remaining)</span
            >
          </td>
        </tr>
        <tr v-if="entitlement.allotted.xps_tb">
          <td>
            <strong>
              <font-awesome-icon
                :icon="['fas', 'fa-bolt-lightning']"
                class="fa-fw"
              ></font-awesome-icon>
              {{ SKUs.WekaXPS }}
            </strong>
          </td>
          <td>
            {{ (entitlement.allotted.xps_tb * TB_TO_B) | prettyBytes }}
          </td>
          <td>
            <span style="color: #999"
              >({{
                (entitlement.remaining.xps_tb * TB_TO_B) | prettyBytes
              }}
              remaining)</span
            >
          </td>
        </tr>
        <tr v-if="entitlement.allotted.xcl_tb">
          <td>
            <strong>
              <font-awesome-icon
                :icon="['fas', 'fa-bolt-lightning']"
                class="fa-fw"
              ></font-awesome-icon>
              {{ SKUs.WekaXCL }}
            </strong>
          </td>
          <td>
            {{ (entitlement.allotted.xcl_tb * TB_TO_B) | prettyBytes }}
          </td>
          <td>
            <span style="color: #999"
              >({{
                (entitlement.remaining.xcl_tb * TB_TO_B) | prettyBytes
              }}
              remaining)</span
            >
          </td>
        </tr>
        <tr v-if="entitlement.allotted.xos_tb">
          <td>
            <strong>
              <font-awesome-icon
                :icon="['fas', 'fa-bolt-lightning']"
                class="fa-fw"
              ></font-awesome-icon>
              {{ SKUs.WekaXOS }}
            </strong>
          </td>
          <td>
            {{ (entitlement.allotted.xos_tb * TB_TO_B) | prettyBytes }}
          </td>
          <td>
            <span style="color: #999"
              >({{
                (entitlement.remaining.xos_tb * TB_TO_B) | prettyBytes
              }}
              remaining)</span
            >
          </td>
        </tr>
        <tr v-if="entitlement.allotted.wpsw_tb">
          <td>
            <strong>
              <font-awesome-icon
                :icon="['fas', 'fa-bolt-lightning']"
                class="fa-fw"
              ></font-awesome-icon>
              {{ SKUs.WekaWPSW }}
            </strong>
          </td>
          <td>
            {{ (entitlement.allotted.wpsw_tb * TB_TO_B) | prettyBytes }}
          </td>
          <td>
            <span style="color: #999"
              >({{
                (entitlement.remaining.wpsw_tb * TB_TO_B) | prettyBytes
              }}
              remaining)</span
            >
          </td>
        </tr>
        <tr v-if="entitlement.allotted.dpo_tb">
          <td>
            <strong>
              <font-awesome-icon
                :icon="['fas', 'fa-cube']"
                class="fa-fw"
              ></font-awesome-icon>
              {{ SKUs.WekaDPO }}
            </strong>
          </td>
          <td>
            {{ (entitlement.allotted.dpo_tb * TB_TO_B) | prettyBytes }}
          </td>
          <td>
            <span style="color: #999"
              >({{
                (entitlement.remaining.dpo_tb * TB_TO_B) | prettyBytes
              }}
              remaining)</span
            >
          </td>
        </tr>
        <tr v-if="entitlement.allotted.dto_tb">
          <td>
            <strong>
              <font-awesome-icon
                :icon="['fas', 'fa-server']"
                class="fa-fw"
              ></font-awesome-icon>
              {{ SKUs.WekaDTO }}
            </strong>
          </td>
          <td>
            {{ (entitlement.allotted.dto_tb * TB_TO_B) | prettyBytes }}
          </td>
          <td>
            <span style="color: #999"
              >({{
                (entitlement.remaining.dto_tb * TB_TO_B) | prettyBytes
              }}
              remaining)</span
            >
          </td>
        </tr>
        <tr v-if="entitlement.allotted.deo_tb">
          <td>
            <strong>
              <font-awesome-icon
                :icon="['fas', 'fa-boxes-stacked']"
                class="fa-fw"
              ></font-awesome-icon>
              {{ SKUs.WekaDEO }}
            </strong>
          </td>
          <td>
            {{ (entitlement.allotted.deo_tb * TB_TO_B) | prettyBytes }}
          </td>
          <td>
            <span style="color: #999"
              >({{
                (entitlement.remaining.deo_tb * TB_TO_B) | prettyBytes
              }}
              remaining)</span
            >
          </td>
        </tr>
        <tr v-if="entitlement.partner">
          <td>
            <strong>
              <font-awesome-icon :icon="['fas', 'fa-user-tie']" class="fa-fw" />
              Partner
            </strong>
          </td>
          <td>
            {{ entitlement.partner }}
          </td>
        </tr>
        <tr v-if="entitlement.end_user">
          <td>
            <strong>
              <font-awesome-icon :icon="['fas', 'fa-user']" class="fa-fw" />
              End User
            </strong>
          </td>
          <td>
            {{ entitlement.end_user }}
          </td>
        </tr>
        <tr
          v-if="
            entitlement.customer_emails &&
            entitlement.customer_emails.length > 0
          "
        >
          <td>
            <strong>
              <font-awesome-icon
                :icon="['fas', 'fa-at']"
                class="fa-fw"
              ></font-awesome-icon>
              Customer contact emails
            </strong>
          </td>
          <td colspan="2">
            <div>
              <span
                v-for="c in entitlement.customer_emails"
                :key="c"
                class="label label-info"
                style="display: inline-block"
              >
                {{ c }}
              </span>
            </div>
          </td>
        </tr>
        <tr v-if="entitlement.opportunities">
          <td>
            <strong>
              <font-awesome-icon
                :icon="['fas', 'fa-eye']"
                class="fa-fw"
              ></font-awesome-icon>
              Opportunities
            </strong>
          </td>
          <td colspan="2">
            <div>
              <span
                v-for="e in entitlement.opportunities.split(',')"
                :key="e"
                class="label label-info"
                style="display: inline-block"
              >
                {{ e }}
              </span>
            </div>
          </td>
        </tr>
      </table>

      <div v-if="comments" style="margin-left: 2px">
        <details>
          <summary>
            <strong>
              <font-awesome-icon
                :icon="['far', 'fa-comment']"
                class="fa-fw"
                style="margin-left: 3px"
              ></font-awesome-icon>
              Comments ({{ comments.length }})
            </strong>
          </summary>

          <div
            v-for="comment in comments"
            :key="comment"
            class="small subtext"
            style="color: #aaa"
          >
            {{ comment }}
          </div>
        </details>
      </div>

      <div
        v-if="
          entitlement.sf_case_id &&
          entitlement.sf_case_id.length > 0 &&
          showSfCaseId
        "
        class="small subtext"
        style="color: #aaa"
      >
        SF Case Ids {{ entitlement.sf_case_id.join(", ") }}
      </div>
      <div
        v-if="entitlement.sf_contract_id && showSfContractId"
        class="small subtext"
        style="color: #aaa"
      >
        SF Contract Id {{ entitlement.sf_contract_id }}
      </div>

      <div v-if="showEntitlementId" class="small subtext" style="color: #aaa">
        Entitlement Id {{ entitlement.id }}
      </div>
      <div v-if="showCreatedBy" class="small subtext" style="color: #aaa">
        Created by
        <user :id="entitlement.created_by"></user>
        at {{ entitlement.created_at | moment("LLL") }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
table.right-padded-cells {
  tr {
    td {
      padding-right: 1.5rem;
    }
  }
}

details > summary::-webkit-details-marker /* Safari */ {
  display: none;
}

details > summary::before {
  content: "►";
  cursor: pointer;
}

details[open] > summary::before {
  content: "▼";
  cursor: pointer;
}
</style>

<script>
import { SKUs, TB_TO_B, isEntitlementExpired } from "@/utils";

export default {
  name: "entitlement",
  props: [
    "entitlement",
    "showCreatedBy",
    "showEntitlementExpired",
    "showEntitlementId",
    "showSfCaseId",
    "showSfContractId",
  ],
  computed: {
    SKUs() {
      return SKUs;
    },
    TB_TO_B() {
      return TB_TO_B;
    },
    comments() {
      return this.entitlement.comments && this.entitlement.comments.split("\n");
    },
  },
  methods: {
    entExpired() {
      return isEntitlementExpired(this.entitlement);
    },
  },
};
</script>
