<template>
  <modal ref="theModal" @done="close" modal-class="modal-lg">

    <div slot="header">
      <h3 v-if="drive">
        {{ drive.disk_id }}
      </h3>
    </div>

    <div slot="body">

      <table class="table table-bordered" v-if="drive">
        <tbody>
          <tr>
            <td><strong>Status</strong></td>
            <td>
              <span class="label label-success" :class="{ 'label-success': drive.status === 'ACTIVE', 'label-danger': drive.status !== 'ACTIVE' }">
                {{ drive.status }}
              </span>
            </td>
          </tr>
          <tr>
            <td><strong>Should Be Active</strong></td>
            <td>{{ drive.should_be_active ? 'Yes' : 'No' }}</td>
          </tr>

          <tr>
            <td colspan="2" style="background:white; border-left:1px solid white; height:2rem; border-right: 1px solid white; padding:0;"></td>
          </tr>

          <tr>
            <td><strong>UUID</strong></td>
            <td>{{ drive.uuid }}</td>
          </tr>
          <tr>
            <td><strong>Disk ID</strong></td>
            <td>{{ drive.disk_id }}</td>
          </tr>
          <tr>
            <td><strong>Failure Domain</strong></td>
            <td>{{ drive.failure_domain }}</td>
          </tr>
          <tr>
            <td><strong>Node ID</strong></td>
            <td>{{ drive.node_id }}</td>
          </tr>
          <tr>
            <td><strong>Host ID</strong></td>
            <td>{{ drive.host_id }}</td>
          </tr>
          <tr>
            <td><strong>Hostname</strong></td>
            <td>{{ drive.hostname }}</td>
          </tr>

          <tr>
            <td colspan="2" style="background:white; border-left:1px solid white; height:2rem; border-right: 1px solid white; padding:0;"></td>
          </tr>

          <tr>
            <td><strong>Attachment</strong></td>
            <td>{{ drive.attachment }}</td>
          </tr>
          <tr>
            <td><strong>Size</strong></td>
            <td>{{ drive.size || drive.size_bytes | prettyBytes }} ({{ drive.size || drive.size_bytes }} bytes)</td>
          </tr>
          <tr>
            <td><strong>Block Size</strong></td>
            <td>{{ drive.block_size }}</td>
          </tr>
          <tr>
            <td><strong>Device Path</strong></td>
            <td>{{ drive.device_path }}</td>
          </tr>
          <tr>
            <td><strong>Firmware</strong></td>
            <td>{{ drive.firmware }}</td>
          </tr>
          <tr>
            <td><strong>Model</strong></td>
            <td>{{ drive.model }}</td>
          </tr>
          <tr>
            <td><strong>Serial Number</strong></td>
            <td>{{ drive.serial_number }}</td>
          </tr>
          <tr>
            <td><strong>Vendor</strong></td>
            <td>{{ drive.vendor }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="text-right" slot="footer">
      <a class="btn btn-link" @click.prevent="close">
        Close
      </a>
    </div>

  </modal>
</template>

<script>
 function initialData() {
     return {
         drive : null,
     }
 }

 export default {
     name: 'drive-info',

     data: initialData,

     methods: {
         show(drive) {
             Object.assign(this, initialData())
             this.drive = drive
             this.$refs.theModal.showModal()
         },
         close() {
             this.$refs.theModal.hideModal()
         },
     },
 }
</script>
