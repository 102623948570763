<template>
  <div>
    <div style="line-height:175%;">

      <span v-if="showOrg">
        <organization :id="object.org_id"></organization>
        <font-awesome-icon style="color:#aaa;" :icon="['fas', 'fa-angle-right']" class="fa-fw"></font-awesome-icon>
      </span>

      <span v-if="object.type === 'aws-marketplace'">
        <font-awesome-icon :icon="['fab', 'amazon']" class="fa-fw" style="color:#f7981d; margin-right:.25em;"></font-awesome-icon>
        AWS Marketplace
      </span>

      <span v-else-if="object.type === 'paypal'">
         <font-awesome-icon :icon="['fab', 'paypal']" class="fa-fw"  style="color:#0070ba; margin-right:.25em;"></font-awesome-icon>
        PayPal
      </span>

      <span class="label label-success" v-if="object.status === 'subscribed'">SUBSCRIBED</span>
      <span class="label label-warning" v-if="object.status === 'awaiting_approval'">AWAITING APPROVAL</span>
      <span class="label label-default" v-if="object.status === 'cancelled'">CANCELLED</span>

    </div>

    <div v-if="object.type === 'aws-marketplace'" style="line-height:175%; opacity:0.75; font-size:smaller;">
      Customer ID: <samp>{{ object.properties.customer_identifier }}</samp>
      &nbsp;/&nbsp;
      Product Code: <samp>{{ object.properties.product_code }}</samp>
    </div>

    <div v-else-if="object.type === 'paypal'" style="line-height:175%; opacity:0.75; font-size:smaller;">
      Email: <samp>{{ object.properties.email }}</samp>
    </div>

    <div style="line-height:175%; font-size:smaller;" v-if="object.status === 'cancelled'">
      <font-awesome-icon :icon="['fas', 'fa-trash']" class="fa-fw"></font-awesome-icon>
      This payment method was cancelled, it will be automatically removed after the last billing occurs
    </div>

    <div v-if="showCreatedAt" style="line-height:175%; opacity:0.70; font-size:smaller;">
      <font-awesome-icon :icon="['far', 'fa-clock']" class="fa-fw"></font-awesome-icon>
      Subscribed at {{ object.created_at | moment('LL') }}
    </div>

  </div>
</template>

<script>
 export default {
     name: 'payment-method',

     props: {
         object: {
             type: Object,
         },
         showOrg: {
             type: Boolean,
             default: false,
         },
         showCreatedAt: {
             type: Boolean,
             default: false,
         },
     },
 }
</script>
