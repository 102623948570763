import axios from 'axios'

const LOCAL_STORAGE_TOKEN_TYPE = 'token_type'
const LOCAL_STORAGE_ACCESS_TOKEN = 'access_token'

export default {
    state: {
        token_type   : null,
        access_token : null,
        cur_user     : null
    },

    getters: {
        authorization(state) {
            if (state.token_type && state.access_token) {
                return `${state.token_type} ${state.access_token}`
            }
            return null
        },
        isPending(state) {
            if (state.cur_user !== null) {
                return !!state.cur_user.pending
            }
            return false
        },
        isAuthenticated(state) {
            return state.cur_user !== null
        },
        currentUser(state) {
            return state.cur_user
        },
        hasAdminPrivileges(state) {
            return (
                state.cur_user &&
                state.cur_user.privileges &&
                (Object.keys(state.cur_user.privileges).length > 0)
            )
        }
    },

    mutations: {
        setAccessToken(state, { token_type, access_token }) {
            state.token_type = token_type
            state.access_token = access_token
        },
        unsetCurrentUser(state) {
            state.token_type = null
            state.access_token = null
            state.cur_user = null
        },
        setCurrentUser(state, cur_user) {
            state.cur_user = cur_user
        }
    },

    actions: {
        async login(context, { email, password, recaptcha_response }) {
            const response = await axios.post('/api/v1/auth/local/login', { email, password, recaptcha_response })
            const { token_type, access_token } = response.data
            await context.dispatch('saveAccessTokenAndLoadUser', { token_type, access_token })
        },
        async logout(context) {
            context.commit('unsetCurrentUser')
            localStorage.removeItem(LOCAL_STORAGE_TOKEN_TYPE)
            localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN)
        },
        async saveAccessTokenAndLoadUser(context, { token_type, access_token }) {
            localStorage.setItem(LOCAL_STORAGE_TOKEN_TYPE, token_type)
            localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, access_token)
            await context.dispatch('loadUser')
        },
        async loadUser(context) {
            const token_type = localStorage.getItem(LOCAL_STORAGE_TOKEN_TYPE)
            const access_token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)
            if (token_type && access_token) {
                context.commit('setAccessToken', { token_type, access_token })
                try {
                    const response = await axios.get('/api/v1/auth/self')
                    context.commit('setCurrentUser', response.data)
                } catch (error) {
                    context.commit('unsetCurrentUser')
                }
            }
        }
    }
}
