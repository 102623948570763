<template>
  <div>
    <div style="position:absolute; top:0; left:0; z-index:-1; opacity:0;">
      <span style="font-weight:100;">100</span>
      <span style="font-weight:200;">200</span>
      <span style="font-weight:300;">300</span>
      <span style="font-weight:400;">400</span>
      <span style="font-weight:500;">500</span>
      <span style="font-weight:600;">600</span>
      <span style="font-weight:700;">700</span>
      <span style="font-weight:100; font-style:italic;">100</span>
      <span style="font-weight:200; font-style:italic;">200</span>
      <span style="font-weight:300; font-style:italic;">300</span>
      <span style="font-weight:400; font-style:italic;">400</span>
      <span style="font-weight:500; font-style:italic;">500</span>
      <span style="font-weight:600; font-style:italic;">600</span>
      <span style="font-weight:700; font-style:italic;">700</span>
      <font-awesome-icon :icon="['fas', 'fa-umbrella']" class="fa-fw"></font-awesome-icon>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
 export default {
     name: 'root'
 }
</script>
