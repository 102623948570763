const KNOWN_FORMATS = [0, 1, 2, 3]

export class UsageReportParser {
    constructor(content) {
        this.content = content
        if (this.content) {
            this.format = this.content.format || 1
        } else {
            this.format = 0
        }
        if (KNOWN_FORMATS.indexOf(this.format) === -1) {
            throw new Error(`Unknown usage report format ${this.format}`)
        }
    }

    name() {
        switch (this.format) {
        case 1:
        case 2:
        case 3:
            return this.content.name
        }
        return ''
    }

    version() {
        switch (this.format) {
        case 1:
            return `${this.content.version.number}`
        case 2:
        case 3:
            return `${this.content.status.release}`
        }
        return ''
    }

    versionFamily() {
        const mo = this.version().match(/^(\d+)\.(\d+)(\.(\d+)(\D.*)?)?/)
        const [ major, minor, micro ] = [ mo[1], mo[2], mo[4] ]
        return `${major}.${minor}.${micro || 0}`
    }

    hostCount() {
        switch (this.format) {
            case 3:
                return this.content.total_hosts
            default:
                return this.hosts().length
        }
    }

    backendCount() {
        switch (this.format) {
            case 3:
                return this.content.total_backends
            default:
                return this.hosts().filter(host => host.is_backend).length
        }

    }

    clientCount() {
        return this.hostCount() - this.backendCount()
    }

    hosts() {
        const hostIdNumeric = host => {
            const mo = host.host_id.match(/HostId<(\d+)>/)
            if (!mo) {
                return host.host_id
            }
            return parseInt(mo[1])
        }
        let result = []
        switch (this.format) {
        case 1:
            result = this.content.hosts || []
            break
        case 2:
            result = Object.entries(this.content.hosts).map(([ host_id, host ]) => Object.assign({}, host, { host_id }))
            break
        case 3:
            result = []
            break
        }
        result = result.map(host => {
            const isBackendRole = role => ['BACKEND', 'COMPUTE', 'DRIVE'].indexOf(role) !== -1

            const aws        = this.hostAwsMetadata(host)
            const nodes      = this.hostNodes(host)
            const drives     = this.hostDrives(host)
            const is_backend = nodes.some(node => node.roles.some(isBackendRole))

            return Object.assign({}, host, {
                aws,
                nodes,
                drives,
                is_backend,
            })
        })
        return result.sort((left, right) => hostIdNumeric(left) - hostIdNumeric(right))
    }

    alerts() {
        return this.content.alerts || []
    }

    alertCount() {
        return this.alerts().length
    }

    activeAlertCount() {
        return this.alerts().filter(alert => !alert.muted).length
    }

    filesystemCount() {
        switch (this.format) {
         case 1:
         case 2:
             return this.content.filesystems.length
         case 3:
             return this.content.total_filesystems || 0

        }
        return 0
    }

    filesystems() {
        switch (this.format) {
         case 1:
         case 2:
             return this.content.filesystems
         case 3:
             return []

        }
        return []
    }

    hostAwsMetadata(host) {
        switch (this.format) {
        case 1:
            if (!host.aws_az || !host.aws_instance_id || !host.aws_instance_type) {
                return null
            }
            return {
                availability_zone : host.aws_az,
                instance_type     : host.aws_instance_type,
                instance_id       : host.aws_instance_id,
            }
        case 2:
            return host.aws
        }
        return null
    }

    hostNodes(host) {
        switch (this.format) {
        case 1:
            return host.nodes.map(node => Object.assign({}, node, { roles: this.nodeRoles(node) }))
        case 2:
            return Object.entries(this.content.nodes)
                         .map(kv => Object.assign({}, kv[1], { node_id: kv[0] }))
                         .filter(node => node.host_id === host.host_id)
        }
        return null
    }

    hostDrives(host) {
        switch (this.format) {
        case 1:
            return host.disks.map(disk => Object.assign({}, disk, {
                size_bytes : disk.size,
            }))
        case 2:
            return Object.entries(this.content.drives)
                         .map(kv => Object.assign({}, kv[1], { disk_id: kv[0] }))
                         .filter(drive => drive.host_id === host.host_id)
        }
        return null
    }

    nodeRoles(node) {
        switch (this.format) {
        case 1:
            if (node.is_frontend && node.is_io_node) {
                return ['FRONTEND', 'BACKEND']
            }
            if (node.is_io_node) {
                return ['BACKEND']
            }
            if (node.is_frontend) {
                return ['FRONTEND']
            }
            return ['MANAGEMENT']
        case 2:
            return node.roles
        }
        return []
    }

    ssdCapacity() {
        switch (this.format) {
            case 3:
                return this.content.drive_capacity
            default:
                return this.hosts().map(host => host.drives.map(drive => drive.size_bytes).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0)

        }
    }

    awsInstances() {
        const aws_hosts = {}
        for (const host of this.hosts()) {
            if (host.aws) {
                const instance_type = host.aws.instance_type
                aws_hosts[instance_type] = 1 + (aws_hosts[instance_type] || 0)
            }
        }
        return aws_hosts
    }
}
