<template>
  <div>
    <div class="panel panel-default">
      <ul class="list-group">
        <li class="list-group-item">
          <h3 style="margin:1rem;">
            <organization :id="cluster.org_id"></organization>
            <font-awesome-icon :icon="['fas', 'fa-angle-right']" class="fa-fw"></font-awesome-icon>
            <font-awesome-icon :icon="['fas', 'fa-cubes']" class="fa-fw"></font-awesome-icon>
            {{ cluster.name }}
            <span style="color:#aaa;" class="small">
              (<samp>{{ cluster.id }}</samp>)
            </span>
          </h3>
          <div style="margin-left:1rem;">
            <span style="margin-right:.5rem;">
              <status-indicator positive v-if="isAlive"></status-indicator>
              <status-indicator v-else></status-indicator>
            </span>
            Last seen {{ cluster.last_seen | moment('from') }}
            <span v-if="hasBirthdayThisWeek">
              <span style="color:#ddd;">&nbsp;|&nbsp;</span>
              <strong>
                <span style="color:#6d8cca;" v-if="hasBirthdayToday">
                  🎉 Today is this cluster's birthday!
                </span>
                <span v-else>
                  🎉 This week is this cluster's birthday!
                </span>
              </strong>
            </span>
          </div>
        </li>
        <li class="list-group-item">
          <p>
            v{{ usageReport.version() }}
          </p>
          <p>
            Registered {{ cluster.created_at | moment('from') }} <span style="color:#aaa;">({{ cluster.created_at | moment('LLL') }})</span>
          </p>
          <div v-if="hasAdminPrivileges">
            <a :href="wekaHomeUrl" target="_blank">
              <font-awesome-icon :icon="['fas', 'arrow-up-right-from-square']" class="fa-fw"></font-awesome-icon>
              Browse cluster events (Weka Home)
            </a>

          </div>
        </li>
      </ul>
    </div>

    <div class="panel" v-if="usageReport.alertCount() > 0" :class="{ 'panel-default': usageReport.activeAlertCount() === 0, 'panel-danger': usageReport.activeAlertCount() > 0 }">
      <div class="panel-heading">
        <h5>
          <strong>
            Alerts ({{ usageReport.alertCount() }} total, {{ usageReport.alertCount() - usageReport.activeAlertCount() }} muted)
          </strong>
        </h5>
      </div>
      <ul class="list-group">
        <li class="list-group-item" v-for="(alert, alert_index) in usageReport.alerts()" :key="alert_index">
          <span class="pull-right label label-warning" v-if="alert.muted">MUTED</span>
          <div :style="{ opacity: alert.muted ? '0.5' : '1' }">
            <strong>
              <font-awesome-icon :icon="['far', alert.muted ?  'fa-bell-slash' : 'fa-bell']" class="fa-fw"></font-awesome-icon>
              {{ pyFormat(alert.title, alert.params) }}
            </strong>
          </div>
          <div :style="{ opacity: alert.muted ? '0.5' : '1' }">
            {{ pyFormat(alert.fmt, alert.params) }}
          </div>
        </li>
      </ul>
    </div>

    <div class="panel panel-default" v-if="usageReport.filesystems()">
      <div class="panel-heading">
        <h5>
          <strong>
            Filesystems ({{ usageReport.filesystemCount() }})
          </strong>
        </h5>
      </div>
      <ul class="list-group">
        <li class="list-group-item" v-for="(filesystem, fs_index) in usageReport.filesystems()" :key="fs_index">
          <font-awesome-icon :icon="['fas', 'fa-sitemap']" class="fa-fw"></font-awesome-icon>
          <strong>{{ filesystem.capacity | prettyBytes }}</strong> Total Capacity,
          <strong>{{ filesystem.ssd_budget | prettyBytes }}</strong> SSD Budget
        </li>
      </ul>
    </div>

    <div class="panel panel-default">
      <div class="panel-heading">
        <h5>
          <strong>
            Hosts ({{ usageReport.hostCount() }})
          </strong>
        </h5>
      </div>
      <table class="table table-bordered">
        <tbody>
          <tr v-for="host in usageReport.hosts()" :key="host.host_id">
            <td style="line-height:175%;">
              <div>
                <strong>
                  {{ host.host_id }}
                </strong>
              </div>
              <div>{{ host.hostname }}</div>
              <div style="color:#aaa;">{{ host.ip || host.host_ip }}</div>
              <div v-if="host.aws">
                <font-awesome-icon :icon="['fab', 'fa-amazon']" class="fa-fw"></font-awesome-icon>
                {{ host.aws.instance_type }}
              </div>
              <div v-if="host.aws">
                {{ host.aws.availability_zone }}
              </div>
              <div v-if="host.aws">
                {{ host.aws.instance_id }}
              </div>
              <div>
                <span style="margin-left:0" class="label label-primary" v-if="host.is_backend">BACKEND</span>
                <span style="margin-left:0" class="label label-primary" v-else>CLIENT</span>
              </div>
            </td>

            <td class="inner-component-table">
              <table>
                <tbody>
                  <tr v-for="node in host.nodes" :key="node.node_id" :class="{ up: node.status === 'UP', down: node.status !== 'UP' }">
                    <td>
                      <span class="label label-success" v-if="node.status === 'UP'">UP</span>
                      <span class="label label-danger" v-else>DOWN</span>
                    </td>
                    <td>
                      {{ node.node_id }}
                    </td>
                    <td>
                      {{ node.roles.join('+') }}
                    </td>
                  </tr>
                  <tr v-for="drive in host.drives" :key="drive.disk_id" :class="{ up: drive.status === 'ACTIVE', down: drive.status !== 'ACTIVE' }">
                    <td>
                      <span class="label label-success" :class="{ 'label-success': drive.status === 'ACTIVE', 'label-danger': drive.status !== 'ACTIVE' }">
                        {{ drive.status }}
                      </span>
                    </td>
                    <td>
                      {{ drive.disk_id }}
                    </td>
                    <td>
                      {{ drive.size || drive.size_bytes | prettyBytes }}
                      <span class="pull-right" v-if="drive.disk_id">
                        <a @click.prevent="$refs.driveInfo.show(drive)">
                           <font-awesome-icon :icon="['fas', 'fa-sliders']" class="fa-fw"></font-awesome-icon>
                        </a>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>

          </tr>
        </tbody>
      </table>
    </div>

    <drive-info ref="driveInfo"></drive-info>

  </div>
</template>

<style lang="scss" scoped>
  td.inner-component-table {
    padding: 0;

    table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: -1px;

      tr {
        border-bottom: 1px solid #eee;

        td {
          border-left: 1px solid #eee;
          padding: 1rem;
          vertical-align: top;

          &:first-child {
            border-left: none;
          }
        }

        &.down {
          background: #f2dede;
        }
      }
    }
  }
</style>

<script>
  import moment                from 'moment'
  // import { UsageReportParser } from '../../../backend/src/usage-reports'
  import { UsageReportParser } from '../backend/usage-reports'
  import DriveInfo             from './drive-info'

  export default {
    name: 'cluster',

    props: [
      'cluster',
    ],

    components: {
      DriveInfo,
    },

    data() {
      return {
        usageReport: new UsageReportParser(this.cluster ? this.cluster.last_usage_report : null),
      }
    },

    computed: {
      wekaHomeUrl() {
        if (this.cluster) {
          return `https://home.weka.io/secure/clusters/${this.cluster.id}/events`;
        }
        return ''

      },
      isAlive() {
        return this.cluster && this.cluster.last_seen && (moment().diff(moment(this.cluster.last_seen), 'minutes') < 180)
      },
      hasBirthdayThisWeek() {
        const now = moment()
        return this.isAlive && this.cluster.created_at && (moment(this.cluster.created_at).year() !== now.year()) && moment(this.cluster.created_at).year(now.year()).isSame(now, 'week')
      },
      hasBirthdayToday() {
        const now = moment()
        return this.hasBirthdayThisWeek && moment(this.cluster.created_at).year(now.year()).isSame(now, 'day')
      },
      hasAdminPrivileges() {
        return this.$store.getters.hasAdminPrivileges
      },
    },

    methods: {
      pyFormat(format, params) {
        let result = format
        for (const param in params) {
          const value = params[param]
          if (value) {
            result = result.replace(`{${param}}`, value)
          }
        }
        return result
      },
    },
  }
</script>
