import axios from 'axios'

export async function getAll({ url, params }) {
    let page = 1
    let last_page = null
    let results = []
    while (!last_page || page <= last_page.num_pages) {
        const res = await axios.get(url, { params: Object.assign({}, params || {}, { page }) })
        last_page = res.data
        results = results.concat(last_page.objects)
        page = last_page.page + 1
    }
    return results
}
