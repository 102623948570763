<template>
  <div>
    <div style="line-height:175%;">
      <span v-if="usageReport.payment_status === 'pending'"         class="label label-payment-status label-warning">PAYMENT PENDING</span>
      <span v-else-if="usageReport.payment_status === 'successful'" class="label label-payment-status label-success">PAYMENT SUCCESSFUL</span>
      <span v-else-if="usageReport.payment_status === 'declined'"   class="label label-payment-status label-danger">PAYMENT DECLINED</span>
      <span v-else-if="usageReport.payment_status === 'duplicate'"  class="label label-payment-status label-default">DUPLICATE REPORT</span>
      <span v-else-if="usageReport.payment_status === 'free'"       class="label label-payment-status label-info">FREE TRIAL</span>
      <span>
        {{ usageReport.created_at | moment('LLL') }}
      </span>
      <span style="opacity:0.5;">
        ({{ usageReport.created_at | moment('from') }})
      </span>
    </div>

    <div style="font-size:smaller; opacity:0.5;">
      Cluster GUID: <samp>{{ usageReport.cluster_guid }}</samp>
      &nbsp;
      ({{ parsedUsageReport.name() }})
    </div>

    <div class="text-success" style="font-size:smaller; opacity:0.75;">
      <strong>
        v{{ parsedUsageReport.version() }}
      </strong>
      <span style="opacity:0.5;">
        &nbsp;/&nbsp;
      </span>
      <strong>{{ parsedUsageReport.backendCount() }}</strong> backends
      <span style="opacity:0.5;">
        &nbsp;/&nbsp;
      </span>
      <strong>{{ parsedUsageReport.clientCount() }}</strong> clients
      <span style="opacity:0.5;">
        &nbsp;/&nbsp;
      </span>
      <strong>
        {{ parsedUsageReport.ssdCapacity() | prettyBytes }}
      </strong>
      SSD capacity
    </div>

  </div>
</template>

<style lang="scss" scoped>
 .label-payment-status {
     margin-right: 0;
     margin-left: 0.25em;
 }
</style>

<script>
 // import { UsageReportParser } from '../../../backend/src/usage-reports'
 import { UsageReportParser } from '../backend/usage-reports'

 export default {
     name: 'usage-report',

     props: [
         'usageReport',
     ],

     data() {
         return {
             parsedUsageReport: new UsageReportParser(this.usageReport.content),
         }
     },
 }
</script>
