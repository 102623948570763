<template>
  <div></div>
</template>

<script>
export default {
  name: "intercom",

  props: ["appId", "userId", "name", "email", "userHash", "createdAt"],

  data() {
    return {
      initialized: false,
    };
  },

  mounted() {
    const w = window;
    w.intercomSettings = {
      app_id: this.appId,
      user_id: this.userId,
      name: this.name,
      email: this.email,
      user_hash: this.userHash,
      created_at: this.createdAt,
    };
    const ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      const d = document;
      const i = function () {
        i.c(arguments);
      };
      i.q = [["boot", w.intercomSettings]];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;

      const s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = `https://widget.intercom.io/widget/${this.appId}`;
      const x = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    }
    this.initialized = true;
  },

  beforeDestroy() {
    if (this.initialized) {
      window.Intercom("shutdown");
    }
  },
};
</script>
