<template>
  <span style="overflow-wrap: break-word;">
    <font-awesome-icon v-if="icon" :icon="['far', 'fa-building']" class="fa-fw" style="padding-right:.5rem;"></font-awesome-icon>
    <span v-if="org">{{ org.name }}</span>
    <span v-else style="font-style:italic;">None</span>
    <span v-if="hasContent" style="color:#ccc;"> / </span>
    <slot></slot>
    <span class="label label-warning" v-if="selfIndicator && org && (org.id === curUser.org_id)">
      <font-awesome-icon :icon="['fas', 'fa-child']" class="fa-fw"></font-awesome-icon>
      you are here
    </span>
  </span>
</template>

<script>
 export default {
     name: 'organization',

     data() {
         return {
             org: null
         }
     },

     props: {
         icon: {
             type: Boolean,
             default: true
         },
         id: {
             type: String
         },
         selfIndicator: {
             type: Boolean,
             default: false
         },
     },

     computed: {
         curUser() {
             return this.$store.getters.currentUser
         },
         hasContent() {
             return !!this.$slots.default
         },
     },

     async mounted() {
         this.org = await this.$store.dispatch('getObject', { collection: 'org', pk: this.id })
     }
 }
</script>
