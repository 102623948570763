<template>
  <span>
    <span v-if="user">
      <template v-if="icon">
         <font-awesome-icon v-if="user.org_role === 'user'" :icon="['far', 'fa-user']" class="fa-fw"></font-awesome-icon>
         <font-awesome-icon v-else-if="user.org_role === 'owner'" :icon="['fas', 'fa-shield-halved']" class="fa-fw"></font-awesome-icon>
         <font-awesome-icon v-else-if="user.org_role === 'bot'" :icon="['fas', 'fa-robot']" class="fa-fw"></font-awesome-icon>
      </template>
      {{ user.display_name }}
      <span v-if="showEmail && user.emails && user.emails.length" style="opacity:0.7;">
        <span style="opacity:0.25;">&nbsp;/&nbsp;</span>
         <font-awesome-icon :icon="['far', 'fa-envelope']" class="fa-fw"></font-awesome-icon>
        {{ user.emails[0] }}
      </span>
    </span>
    <span v-else>...</span>
    <span v-if="hasContent" style="opacity:0.25;">&nbsp;/&nbsp;</span>
    <slot></slot>
    <span class="label label-warning" v-if="user && selfIndicator && (user.id === curUser.id)">
      <font-awesome-icon :icon="['fas', 'fa-child']" class="fa-fw"></font-awesome-icon>
      you
    </span>
  </span>
</template>

<script>
 export default {
     name: 'user',

     data() {
         return {
             user: null
         }
     },

     props: [
         'id',
         'object',
         'icon',
         'showEmail',
         'selfIndicator',
     ],

     computed: {
         hasContent() {
             return !!this.$slots.default
         },
         curUser() {
             return this.$store.getters.currentUser
         },
     },

     async mounted() {
         if (this.object) {
             this.user = this.object
         } else {
             this.user = await this.$store.dispatch('getObject', { collection: 'user', pk: this.id })
         }
     }
 }
</script>
