<template>
  <span class="text-center">
    <pulse-loader size="8px" :color="color_" :style="style" />
  </span>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader";

export default {
  name: "WekaSpinner",

  props: ["color", "inline"],

  computed: {
    color_() {
      return this.color || "#6B00DD";
    },
    style() {
      return this.inline ? "display:inline;" : "";
    },
  },
  components: {
    PulseLoader,
  },
};
</script>
