export default [
    {
        name      : 'login',
        path      : '/login',
        component : () => import(/* webpackChunkName: "auth.login" */ './login'),
    },
    {
        name      : 'pending',
        path      : '/pending',
        component : () => import(/* webpackChunkName: "auth.pending" */ './pending'),
    },
    {
        name      : 'first-user',
        path      : '/login/first-user',
        component : () => import(/* webpackChunkName: "auth.first-user" */ './first-user'),
    },
    {
        name      : 'register-start',
        path      : '/login/register',
        component : () => import(/* webpackChunkName: "auth.register-start" */ './register-start'),
    },
    {
        name      : 'register-finish',
        path      : '/login/register/:token',
        component : () => import(/* webpackChunkName: "auth.register-finish" */ './register-finish'),
    },
    {
        name      : 'password-reset-start',
        path      : '/login/password-reset',
        component : () => import(/* webpackChunkName: "auth.password-reset-start" */ './password-reset-start'),
    },
    {
        name      : 'password-reset-finish',
        path      : '/login/password-reset/:token',
        component : () => import(/* webpackChunkName: "auth.password-reset-finish" */ './password-reset-finish'),
    },
    {
        name      : 'password-setup',
        path      : '/login/password-setup/:token',
        component : () => import(/* webpackChunkName: "auth.password-setup" */ './password-setup'),
    },
    {
        name      : 'google-callback',
        path      : '/login/google/callback',
        component : () => import(/* webpackChunkName: "auth.google-callback" */ './google-callback'),
    },
    {
        name      : 'logout',
        path      : '/logout',
        component : () => import(/* webpackChunkName: "auth.logout" */ './logout'),
    },
    {
        name      : 'oauth',
        path      : '/oauth',
        component : () => import(/* webpackChunkName: "auth.oauth" */ './oauth'),
    },
    {
        name      : 'aws-register',
        path      : '/register/aws/:token',
        component : () => import(/* webpackChunkName: "auth.aws-register" */ './aws-register'),
    },
]
