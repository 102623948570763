import axios from 'axios'
import { getAll } from './get-all'

export default {
    state: {
        collections: new Map(),
        cur_org: null
    },

    mutations: {
        storeObject(state, { collection, pk, object }) {
            if (!state.collections.has(collection)) {
                state.collections.set(collection, new Map())
            }
            state.collections.get(collection).set(pk, object)
        },
        removeObject(state, { collection, pk }) {
            if (!state.collections.has(collection)) {
                state.collections.set(collection, new Map())
            }
            state.collections.get(collection).delete(pk)
        }
    },

    actions: {
        forceGetObject(context, { collection, pk }) {
            const promise = new Promise((resolve, reject) => {
                axios.get(`/api/v1/${collection}/${pk}`).then(
                    res => {
                        resolve(res.data)
                        context.commit('storeObject', { collection, pk, object: res.data })
                    },
                    err_res => reject(err_res.data)
                )
            })
            context.commit('storeObject', { collection, pk, object: promise })
        },
        getObject(context, { collection, pk }) {
            if (!context.state.collections.has(collection) ||
                !context.state.collections.get(collection).has(pk)) {
                context.dispatch('forceGetObject', { collection, pk })
            }
            return context.state.collections.get(collection).get(pk)
        },
        async preFetch(context, { collection, pk_field }) {
            const objects = await getAll({ url: `/api/v1/${collection}` })
            for (const object of objects) {
                context.commit('storeObject', { collection, pk: object[pk_field], object })
            }
        },
        getAllObjects(context, { collection }) {
            if (!context.state.collections.has(collection)) {
                return []
            }
            return Promise.all(context.state.collections.get(collection).values())
        }
    }
}
