<template>
  <ul class="list-group">
    <li v-if="error" class="list-group-item">
      <error-box :error="error" title="Oh no"></error-box>
    </li>

    <li v-if="loading" class="list-group-item text-center">
      <weka-spinner></weka-spinner>
    </li>

    <template v-else-if="results && results.num_results">
      <slot name="before-results"> </slot>
      <slot
        v-for="object in results.objects"
        :object="object"
        name="outer-object"
      >
        <li :key="object.id" class="list-group-item">
          <table style="width: 100%">
            <tr>
              <td>
                <slot :object="object" name="object"></slot>
              </td>
              <td
                v-if="objectActionsIf"
                :style="{
                  width: '1em',
                  'vertical-align': actionsButtonAlign || 'top',
                }"
              >
                <div class="dropdown">
                  <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-link dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'fa-ellipsis-v']"
                      class="fa-fw"
                    ></font-awesome-icon>
                  </button>
                  <ul class="dropdown-menu dropdown-menu-right">
                    <slot :object="object" name="object-actions"></slot>
                  </ul>
                </div>
              </td>
            </tr>
          </table>
        </li>
      </slot>
      <li
        v-if="results.num_results !== 1 || !oneResultHidesPagination"
        class="list-group-item"
      >
        <table style="width: 100%">
          <tr>
            <td
              v-if="!isFirstPage || !isLastPage"
              class="small"
              style="color: #aaa"
            >
              <button
                v-if="!isFirstPage"
                class="btn btn-link"
                @click="gotoPage(cur_page - 1)"
              >
                <font-awesome-icon
                  :icon="['fas', 'fa-chevron-left']"
                  class="fa-fw"
                ></font-awesome-icon>
              </button>
              Showing {{ firstIndex }} to {{ lastIndex }} of
              {{ results.num_results }} {{ namePlural || "results" }}
              <button
                v-if="!isLastPage"
                class="btn btn-link"
                @click="gotoPage(cur_page + 1)"
              >
                <font-awesome-icon
                  :icon="['fas', 'fa-chevron-right']"
                  class="fa-fw"
                ></font-awesome-icon>
              </button>
            </td>
            <td v-else class="small" style="color: #aaa">
              Total {{ results.num_results }}
              {{
                results.num_results === 1
                  ? nameSingular || "result"
                  : namePlural || "results"
              }}
            </td>
            <td class="text-right">
              <slot name="action-buttons"></slot>
            </td>
          </tr>
        </table>
      </li>
    </template>

    <li v-else class="list-group-item">
      <slot name="no-results"></slot>
    </li>
  </ul>
</template>

<script>
import axios from "axios";
import parseInt from "parse-int";
import isEqual from "lodash/isEqual";
import ErrorBox from "./error-box";
import WekaSpinner from "./weka-spinner";

export default {
  name: "paginated-table",

  props: [
    "collectionUrl",
    "query",
    "objectActionsIf",
    "nameSingular",
    "namePlural",
    "oneResultHidesPagination",
    "numItems",
    "actionsButtonAlign",
  ],

  data() {
    return {
      loading: true,
      error: null,
      cur_page: null,
      results: null,
      last_query: null,
    };
  },

  computed: {
    pageSize() {
      return this.numItems || 10;
    },
    firstPage() {
      return 1;
    },
    noPage() {
      return 0;
    },
    lastPage() {
      return this.results ? this.results.num_pages : this.firstPage;
    },
    isFirstPage() {
      return this.cur_page <= this.firstPage;
    },
    isLastPage() {
      return this.cur_page >= this.lastPage;
    },
    firstIndex() {
      return 1 + (this.results.page - 1) * this.results.page_size;
    },
    lastIndex() {
      return this.firstIndex + this.results.objects.length - 1;
    },
  },

  components: {
    ErrorBox,
    WekaSpinner,
  },

  async mounted() {
    this.cur_page = parseInt(this.$route.query.page) || this.firstPage;
    this.reload();
  },

  methods: {
    gotoPage(page) {
      this.cur_page = page;
      this.reload();
    },
    async reload() {
      const initial_page = this.cur_page;
      this.loading = true;
      await this.loadCurrentPage();
      if (!this.error) {
        this.fixPageNumber();
        if (initial_page !== this.cur_page) {
          // Page number was fixed
          await this.loadCurrentPage();
        }
      }
      this.last_query = this.query;
      this.loading = false;
    },
    async loadCurrentPage() {
      try {
        const params = Object.assign({}, this.query || {}, {
          page: this.cur_page,
          page_size: this.pageSize,
        });
        const res = await axios.get(this.collectionUrl, { params });
        this.results = res.data;
      } catch (error) {
        this.error = error;
      }
    },
    fixPageNumber() {
      const num_pages = this.results ? this.results.num_pages : 1;
      this.cur_page = Math.min(Math.max(this.cur_page, 1), num_pages);
      if (this.cur_page === this.firstPage || this.cur_page === this.noPage) {
        this.$router.replace({ query: {} });
      } else {
        this.$router.replace({
          query: Object.assign({}, this.$route.query, { page: this.cur_page }),
        });
      }
    },
  },

  watch: {
    collectionUrl() {
      this.$nextTick(this.reload);
    },
    query() {
      if (!isEqual(this.last_query, this.query)) {
        this.$nextTick(this.reload);
      }
    },
  },
};
</script>
