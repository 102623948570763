<template>
  <div class="alert alert-danger" v-if="error">
    <p>
      <strong>
        <font-awesome-icon :icon="['fas', 'fa-circle-exclamation']" class="fa-fw"></font-awesome-icon>
        {{ title }}
      </strong>
    </p>
    <p v-if="error.config && error.response" style="white-space: pre-wrap;">
      <!-- This error is from a request -->
      {{ error.response.data.message || error.response.data.error }}
    </p>
    <p v-else-if="error.message" style="white-space: pre-wrap;">
      {{ error.message }}
    </p>
    <p v-else style="white-space: pre-wrap;">
      {{ error }}
    </p>
  </div>
</template>

<script>
 export default {
     name: 'error-box',

     props: [
         'title',
         'error'
     ]
 }
</script>
