<template>
  <div>
    <weka-spinner v-if="loading"></weka-spinner>
    <div id="_recaptcha_elem" align="center"></div>
  </div>
</template>

<script>
 import WekaSpinner from './weka-spinner'

 export default {
     name: 'recaptcha',

     props: [
         'siteKey',
         'value'
     ],

     components: {
         WekaSpinner
     },

     data() {
         return {
             loading: true
         }
     },

     mounted() {
         this.loadRecaptcha()
     },

     methods: {
         loadRecaptcha() {
             const self = this
             if (typeof window.grecaptcha === 'undefined') {
                 const script = document.createElement('script')
                 script.type = 'text/javascript'
                 script.async = true
                 script.src = 'https://www.google.com/recaptcha/api.js'
                 script.addEventListener('load', self.initRecaptcha.bind(self))
                 var elem = document.getElementsByTagName('script')[0]
                 elem.parentNode.insertBefore(script, elem)
             } else {
                 self.initRecaptcha()
             }
         },
         initRecaptcha() {
             window.grecaptcha.ready(this.recaptchaReady.bind(this))
         },
         recaptchaReady() {
             const options = {
                 sitekey            : this.siteKey,
                 callback           : this.recaptchaAcquired.bind(this),
                 'expired-callback' : this.recaptchaExpired.bind(this)
             }
             window.grecaptcha.render('_recaptcha_elem', options)
             this.loading = false
             this.$forceUpdate()
         },
         recaptchaAcquired(response) {
             this.$emit('input', response)
             this.$forceUpdate()
         },
         recaptchaExpired() {
             this.$emit('input', null)
             this.$forceUpdate()
         }
     }
 }
</script>
