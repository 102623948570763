<template>
  <div class="form-group" :class="{ 'has-error': hasError }">
    <label>{{ label }}</label>
    <input name="cluster_guid" type="text" class="form-control" :value="value" @input="updated" :placeholder="label" required
           autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false">
    <p class="small help-block">
      Your cluster GUID looks like 00000000-0000-0000-0000-000000000000, you can find it in the cluster UI or by running the <samp>weka status</samp> command.
    </p>
  </div>
</template>

<script>
 export default {
     name: 'cluster-guid-input',

     props: [
         'label',
         'value',
     ],

     data() {
         return {
             hasError: false,
         }
     },

     mounted() {
         this.check(this.value)
     },

     methods: {
         updated(e) {
             this.check(e.target.value)
         },
         check(value) {
             this.hasError = false
             if (value && value.length) {
                 if (!value.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i)) {
                     this.hasError = true
                 }
             }
             this.$emit('input', value)
         },
     },
 }
</script>
