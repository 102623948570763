import Vue         from 'vue'
import Vuex        from 'vuex'
import authStore   from './auth/store'
import objectStore from './objects/store'

Vue.use(Vuex)

export default new Vuex.Store({
    strict: true,
    modules: {
        auth: authStore,
        objects: objectStore
    }
})
